import React from 'react';
import { Layout } from '../components/Layout';
import { Main } from '../components/Main';
import { Content } from '../components/Content';

export default () => (
  <Layout>
    <Main>
      <h1>Stránka nebola nájdená</h1>
      <Content>
        <p>Pravdepodobne ste zadali zlú adresu.</p>
      </Content>
    </Main>
  </Layout>
);
